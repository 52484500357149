import React from "react"
import { splitUpdateLabel } from "@lesmills/gatsby-theme-common"

type Props = {|
  label: string,
|}
const UpdateLabel = ({ label }: Props) => {
  const labelSubStrings = splitUpdateLabel(label)

  return (
    <>
      <span>{labelSubStrings[0]}</span>
      <span className="hidden md:inline">&nbsp;{labelSubStrings[1]}</span>
    </>
  )
}

export default UpdateLabel
