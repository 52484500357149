import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import { Icon } from "@lesmills/gatsby-theme-common"

import type { PaymentMethodType } from "../../types/PaymentMethodType"

type Props = {|
  paymentMethod: PaymentMethodType,
|}

const PaymentMethod = ({ paymentMethod }: Props) => {
  const type = paymentMethod.cardType || paymentMethod.type || ""
  const cardType = type.toLowerCase()
  const iconName = cardType === "paypalaccount" ? "paypal" : cardType
  return (
    <span className="flex items-center">
      <Icon icon={iconName} classNames="w-8 h-5 bg-contain md:w-10 md:h-6" />
      <span>
        {iconName === "klarna"
          ? "Klarna"
          : iconName === "paypal"
          ? paymentMethod.email
          : `**** **** **** ${paymentMethod.last4}`}
      </span>
    </span>
  )
}

export default memo(PaymentMethod, (prevProps, nextProps) => {
  return isEqual(prevProps.paymentMethod, nextProps.paymentMethod)
})
