// flow
import * as React from "react"

import type {
  SubscriptionType,
  AllLayoutsType,
} from "@lesmills/gatsby-theme-common"
import type { MyAccountPageType } from "../../types/MyAccountPageType"
import type { PaymentMethodType } from "../../types/PaymentMethodType"
import {
  Button,
  formatDateTime,
  convertCentsToEuro,
  formatIntervalUnit,
  DATETIME_FORMAT,
  CURRENCIES,
  isAffiliateUser,
} from "@lesmills/gatsby-theme-common"

import InformationRow from "../InformationRow"
import PaymentMethod from "../PaymentMethod"
import AccountManagementModal from "../AccountManagementModal"
import UpdateLabel from "../UpdateLabel"

type Props = {|
  handleDismiss?: () => void | Promise<void>,
  handleRestartSubscription: () => void | Promise<void>,
  handleOpenChangeSubscriptionType?: () => void | Promise<void>,
  subscription: SubscriptionType,
  data: MyAccountPageType,
  paymentMethod: PaymentMethodType,
  nextSubscription?: SubscriptionType,
  layoutData?: AllLayoutsType,
  isNotIncludedTax?: Boolean,
  plusTaxText: String,
  isIAP: Boolean,
  isEntitledToFreeTrial: Boolean,
|}

const RestartSubscription = ({
  handleDismiss,
  subscription,
  handleOpenChangeSubscriptionType,
  data,
  handleRestartSubscription,
  paymentMethod,
  nextSubscription = {},
  layoutData = {},
  isNotIncludedTax,
  plusTaxText,
  isIAP,
  isEntitledToFreeTrial,
}: Props) => {
  const [isProcessing, setIsProcessing] = React.useState(false)
  const {
    restart_subscription_title = {},
    cancel_button_label = {},
    save_button_label = {},
    payment_note_without_trial = {
      text: "",
    },
    payment_note_with_trial = {
      raw: [],
    },
    change_subscription_type = {},
    body1 = [],
    include_tax = {},
  } = data || {}

  const [
    selectedSubscriptionLabel = {
      subscription_field: {},
    },
    priceLabel = {
      subscription_field: {},
    },
    paymentMethodLabel = {
      subscription_field: {},
    },
    nextBillingDateLabel = {
      subscription_field: {},
    },
  ] = body1[0] ? body1[0].items : []

  const { product = {}, current_period_ends_at, total_price_in_cents } =
    subscription || {}
  const nextBillingDate = formatDateTime(
    current_period_ends_at,
    DATETIME_FORMAT.default,
    layoutData
  )
  const { name, product_price_point = {}, currency } = product || {}
  const price = convertCentsToEuro(total_price_in_cents, currency)
  const hasNextSubscription = Object.keys(nextSubscription).length > 0
  const nextSubscriptionName = hasNextSubscription
    ? nextSubscription.name
    : name
  const nextProductEndPoint = nextSubscription.product_price_point || {}
  const nextSubscriptionPrice = hasNextSubscription
    ? convertCentsToEuro(
        nextProductEndPoint.price_in_cents,
        nextSubscription.currency
      )
    : price
  const nextCurrency = hasNextSubscription
    ? nextSubscription.currency
    : currency

  const handleSubmit = () => {
    // form validator

    setIsProcessing(true)

    // call API
    handleRestartSubscription(setIsProcessing)
  }

  const renderPaymentNoteWithTrial = () => (
    <>
      {payment_note_with_trial.raw.map((note, index) => (
        <p className="mt-5" key={"note" + "-" + index}>
          {note.text
            .replace("@billing_date", nextBillingDate)
            .replace(
              "@price",
              `${CURRENCIES[nextCurrency]}${nextSubscriptionPrice}${
                // https://lesmillsinternational.atlassian.net/browse/LA-1221
                // The price of subscription in US and CA is not included tax
                // Add "plus Tax" right after price in US and CA
                isNotIncludedTax ? `(${plusTaxText})` : ""
              }`
            )
            .replace("@subscription", nextSubscriptionName)}
        </p>
      ))}
    </>
  )

  const renderPaymentNotes = () => (
    <>
      {isEntitledToFreeTrial ? (
        renderPaymentNoteWithTrial()
      ) : (
        <p className="mt-5">
          {payment_note_without_trial.text
            .replace(
              "@price",
              `${CURRENCIES[nextCurrency]}${nextSubscriptionPrice}${
                // https://lesmillsinternational.atlassian.net/browse/LA-1221
                // The price of subscription in US and CA is not included tax
                // Add "plus Tax" right after price in US and CA
                isNotIncludedTax ? `(${plusTaxText})` : ""
              }`
            )
            .replace("@subscription", nextSubscriptionName)}
        </p>
      )}
    </>
  )

  return (
    <AccountManagementModal
      handleDismiss={handleDismiss}
      title={restart_subscription_title.text}
      classNames={{ wrapper: "max-w-595" }}
      testId="restart-subscription-modal"
    >
      <div className="flex justify-between items-baseline">
        <InformationRow
          label={
            selectedSubscriptionLabel.subscription_field &&
            selectedSubscriptionLabel.subscription_field.text
          }
          value={name}
          testId="selected-subscription-type"
        />
        {/* // AB2B-695: Hide change subscription link for applepay */}
        {/* LA-1339: Use both offer_tune_id and offer_id to indicate affiliate user */}
        {!isAffiliateUser(subscription) && !isIAP && (
          <Button
            className="underline text-3xs leading-2normal md:text-2lg font-base-light md:leading-7none text-gray-800"
            handleOnClick={handleOpenChangeSubscriptionType}
            testId="change-subscription-type-btn"
            disabled={isProcessing}
            submitting={isProcessing}
          >
            <UpdateLabel label={change_subscription_type.text} />
          </Button>
        )}
      </div>
      <InformationRow
        label={priceLabel.subscription_field.text}
        value={`${CURRENCIES[currency]}${price}/${formatIntervalUnit(
          product_price_point,
          layoutData,
          true
        )} (${isNotIncludedTax ? plusTaxText : include_tax.text})`}
        // https://lesmillsinternational.atlassian.net/browse/LA-1221
        // The price of subscription in US and CA is not included tax
        // Add "plus Tax" right after price in US and CA
        testId="price"
      />
      <InformationRow
        label={
          paymentMethodLabel.subscription_field &&
          paymentMethodLabel.subscription_field.text
        }
        value={<PaymentMethod paymentMethod={paymentMethod} />}
        testId="payment-method"
      />
      <InformationRow
        label={
          nextBillingDateLabel.subscription_field &&
          nextBillingDateLabel.subscription_field.text
        }
        value={nextBillingDate}
        testId="next-billing-date"
      />
      <div className="font-base-light mt-25 mb-30 text-gray-800 leading-snug md:leading-7none md:text-2lg text-base">
        {renderPaymentNotes()}
      </div>
      <div className="flex justify-between flex-wrap">
        <Button
          handleOnClick={handleDismiss}
          disabled={isProcessing}
          className="btn btn-layout w-full md:w-48/100 md:mb-0 mb-4"
          testId="cancel-btn"
        >
          {cancel_button_label.text}
        </Button>
        <Button
          handleOnClick={() => handleSubmit()}
          disabled={isProcessing}
          submitting={isProcessing}
          className="btn btn-primary uppercase w-full py-3 md:w-48/100"
          testId="confirm-btn"
        >
          {save_button_label.text}
        </Button>
      </div>
    </AccountManagementModal>
  )
}

export default RestartSubscription
