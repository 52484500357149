import React, { memo } from "react"

type Props = {|
  label: string | Node,
  value: string | Node,
  classNames?: Object,
  testId?: string,
|}

const InformationRow = ({
  label,
  value,
  classNames = {},
  testId = "",
}: Props) => (
  <div
    className={`flex flex-col mb-6 md:mt-25 mt-20${
      classNames.wrapper ? classNames.wrapper : ""
    }`}
  >
    <label
      className={`font-base-black text-base leading-4none md:text-2lg md:leading-none text-gray-400 mb-3${
        classNames.label ? classNames.label : ""
      }`}
      data-cy={testId ? `${testId}-label` : ""}
      data-testid={testId ? `${testId}-label` : ""}
    >
      {label}
    </label>
    <p
      className={`font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal text-3xs${
        classNames.value ? classNames.value : ""
      }`}
      data-cy={testId ? `${testId}-value` : ""}
      data-testid={testId ? `${testId}-value` : ""}
    >
      {value}
    </p>
  </div>
)

export default memo(InformationRow)
